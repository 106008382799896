@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.page-cover {
    background: #BFDCCD;
    min-height: calc(100vh - 112px);
    padding: 72px 20px 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-result {
    background: #fff;
}

.payment-result,
.subscriber-cover {
    border-radius: 20px;
    max-width: 864px;
    margin: auto;
}

/* payment result fail */
.payment-fail-result {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.payment-fail,
.payment-success {
    text-align: center;
}

.fail-tt,
.success-tt {
    color: #FF4949;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 10px;
}

.failed-txt,
.success-txt {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333;
    margin-top: 10px;
}

.success-tt {
    color: #333;
}

.try-again-link {
    display: inline-block;
    margin-top: 64px;
    min-width: 200px;
    height: 48px;
    background: #fff;
    border: 1px solid #2AB76E;
    border-radius: 10px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    line-height: 48px;
    transition: all .4s ease;
}

.try-again-link:hover {
    background: #2AB76E;
    color: #fff;
}

/* success payment result */
.payment-success-result,
.payment-fail-result {
    padding-top: 69px;
    padding-bottom: 69px;
    min-height: auto;
    width: 100%;
}

.payment-fail-result {
    min-height: 544px;
}

.payment-info {
    max-width: 640px;
    margin: 30px auto 0 auto;
    background: #FAFAFA;
    border-radius: 20px;
}

.payment-info-head {
    background: #2AB76E;
    padding: 16px 40px;
    border-radius: 20px 20px 0px 0px;
}

.payment-info-head,
.payment-info-details li {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-info-head p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}

.payment-info-head p,
.payment-info-head span {
    color: #fff;
}

.payment-info-head span {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.payment-info-details {
    list-style-type: none;
    margin: 0;
    padding: 30px 40px;
}

.payment-info-details p,
.payment-info-details span {
    margin: 0;
    color: rgba(0, 0, 0, 0.75);
    font-size: 16px;
    line-height: 24px;
}

.payment-info-details p {
    font-weight: 500;
}

.payment-info-details span {
    font-weight: 300;
}

.payment-info-details li:not(:last-child) {
    margin-bottom: 16px;
}

.subsc-footer {
    background: #fff;
    border-radius: 20px;
    background-image:url('../imgs/footer-bg.png');
    background-repeat:no-repeat;
    background-position:left 50px bottom 0;
    margin-top:20px;
}

.subscriber-cover {
    width: 100%;
}

.subsc-footer-content {
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subsc-sosials,
.subsc-footer-info {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.subsc-sosials li {
    display: inline-block;
    margin-left: 20px;
}

.subsc-sosials li a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: rgba(42, 183, 110, 0.2);
    border-radius: 6px;
    width:30px;
    height:30px;
    transition:all .4s ease;
}
.subsc-sosials li a:hover{
    transform:scale(1.1);
}
.subsc-footer-p,.subsc-footer-info li a{
    font-size:15px;
    font-weight:400;
}
.subsc-footer-p{
    margin-top: 0;
    margin-bottom:4px;
    color: rgba(51, 51, 51, 0.5);
}
.subsc-footer-info li a{
    color:#2AB76E;
    text-decoration:none;
    transition: all .4s ease;
}
.subsc-footer-info li a:hover{
    text-decoration:underline;
}
.subsc-section{
    background:#fff;
    border-radius:20px;
}
.subsc-section-content{
    padding:40px;
    display:flex;
    justify-content:space-between;
}
.subsc-left,.subsc-right{
    width:50%;
    padding-top:20px;
}
.subsc-right-top{
    text-align:right;
}
.subsc-left{
    padding-right:40px;
    border-right:1px solid rgba(51, 51, 51, 0.2);
}
.form-input{
    height:48px;
    background: #fff;
    border: 1px solid #D7D8DF;
    border-radius: 10px;
    width:calc(100% - 34px);
    padding-left:16px;
    padding-right:16px;
    font-weight: 400;
    font-size: 14px;
    color:#333;
    transition:all .4s ease;
}
.form-input:focus{
    box-shadow:none;
    outline:none;
    border:1px solid #2AB76E;
}
.subsc-form{
    margin-top:62px;
}
.subsc-continue{
    margin-top:165px;
}
.subsc-continue,.pay-btn{
    height:48px;
    width:100%;
    padding-left:16px;
    padding-right:16px;
    border-radius:10px;
    background-color:#2AB76E;
    border:1px solid #2AB76E;
    font-size: 15px;
    font-weight: 600;
    color:#fff;
    cursor: pointer;
    transition:all .4s ease;
}
.subsc-continue:hover,.pay-btn:hover{
    background-color: #21b066;
}
.form-input[type=number]::-webkit-inner-spin-button, 
.form-input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.form-input::-webkit-input-placeholder { 
    color: #BDBDBD;
}
.form-input::-moz-placeholder { 
    color: #BDBDBD;
}
.form-input:-ms-input-placeholder {
    color: #BDBDBD;
}
.form-input:-moz-placeholder {
    color: #BDBDBD;
}
.subsc-continue[disabled],.pay-btn[disabled]{
    background: rgba(42, 183, 110, 0.5);
    border-color:rgba(42, 183, 110, 0.06);
    cursor:not-allowed !important;
}
.subsc-data{
    margin:0;
    list-style-type: none;
    margin-top:40px;
}
.subsc-data li{
    display:flex;
    justify-content: space-between;
    align-items:center;
    padding-top:20px;
    padding-bottom:20px;
}
.subsc-data li:not(:last-child){
    border-bottom:1px solid rgba(51, 51, 51, 0.1);
}
.subsc-data li p{
    margin:0;
    color:#333;
    font-size:16px;
    font-weight:400;
    line-height:24px;
    padding-left:8px;
}
.subsc-data li span{
    color:rgba(51, 51, 51, 0.5);
    font-size:15px;
    font-weight:400;
    line-height:22px;
}
.payment-btns{
    padding-left:40px;
    margin-top:105px;
    display:flex;
    align-items:center;
}
.back-btn{
    display: inline-block;
    height:48px;
    border-radius:10px;
    background:#fff;
    border: 1px solid rgba(51, 51, 51, 0.2);
    padding-left:16px;
    padding-right:16px;
    min-width:90px;
    text-align:center;
    text-decoration:none;
    line-height:48px;
    color:#333;
    font-size:14px;
    font-weight:400;
    margin-right:20px;
    transition:all .4s ease;
}
.back-btn:hover{
    border-color:#21b066;
    color:#21b066;
}
.form-element{
    position:relative;
}
.azn-icon{
    position: absolute;
    right:20px;
    top:20px;
}
.payment-type{
    margin-top:16px;
}
.payment-type .css-1s2u09g-control,.payment-type .css-1pahdxg-control{
    border: 1px solid #D7D8DF;
    border-radius: 10px;
    height:48px;
    min-height:48px;
    box-shadow:none !important;
    transition: all .4s ease;
}
.payment-type .css-1pahdxg-control:hover{
    border-color:#21b066;
}
.payment-type .css-1okebmr-indicatorSeparator,.payment-type svg{
    display:none !important;
}
.payment-type .css-tlfecz-indicatorContainer{
    width:20px;
    height:20px;
    margin-right:14px;
}
.select-icon{
    position:absolute;
    right:15px;
    top:14px;
    pointer-events:none;
}
.payment-type .css-qc6sy-singleValue{
    font-size:14px;
    font-weight:400;
    color:#333;
    margin-top:2px;
}
.payment-type .css-14el2xx-placeholder{
    font-size:14px;
    font-weight:400;
    color:#BDBDBD !important;
}
.payment-type .css-319lph-ValueContainer{
    padding-left:14px;
    padding-top:0;
}
.payment-type .css-26l3qy-menu{
    background-color: #fff !important;
    border: 1px solid #D7D8DF;
    box-shadow:none !important;
    font-size:14px;
    font-weight:400;
    border-radius:10px;
}
.payment-type-img{
    display:flex;
    align-items:center;
}
.payment-type-img span{
    display:inline-flex;
    align-items:center;
    justify-content:center;
    width:46px;
    height:46px;
    background: #FEFEFF;
    border: 1px solid #EFEEEE;
    border-radius: 10px;
    margin-right:16px;
}
.payment-type .css-qc6sy-singleValue span{
    border:0;
    height:auto;
    margin-right:12px;
}
.payment-success-img,.payment-fail-img{
    max-height:80px;
}
.subsc-top-imgs .subsc-right-img{
    display:none;
}
@media screen and (max-width:992px){
    .payment-fail-result{
        min-height:auto;
    }
    .page-cover{
        background:#fff;
        min-height:auto;
    }
    .payment-success-result,.payment-fail-result{
        padding:20px;
    }
    .subsc-footer,.subsc-section{
        border:1px solid rgba(51, 51, 51, 0.2);
    }
    .subsc-data li p,.subsc-data li span{
        font-size:14px;
    }
}
@media screen and (max-width:767px){
    .subsc-section-content{
        padding:0;
    }
    .subsc-section-content{
        flex-direction:column;
    }
    .page-cover{
        padding-top:60px;
    }
    .subsc-left, .subsc-right{
        width:100%;
    }
    .subsc-left{
        padding-right:0;
        padding-top:0;
        border:0;
    }
    .subsc-section{
        border:1px solid rgba(51, 51, 51, 0.2);
        padding:40px;
    }
    .subsc-data{
        padding-left:0;
        margin-top:0;
    }
    .subsc-right{
        margin-top:0;
        padding-top:24px;
    }
    .subsc-form{
        margin-top:50px;
    }
    .subsc-continue{
        margin-top:24px;
    }
    .subsc-left-img{
        width:118px;
    }
    .subsc-right-img{
        width:50px;
    }
    .payment-btns{
        padding-left:0;
    }
    .subsc-right-top .subsc-right-img{
        display:none;
    }
    .subsc-top-imgs .subsc-right-img{
        display:block;
    }
    .subsc-top-imgs{
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
}
@media screen and (max-width:576px){
    .payment-info-details li{
        flex-direction:column;
        align-items:flex-start;
    }
    .payment-info-head{
        padding:16px 20px;
    }
    .payment-info-head p,.payment-info-details p, .payment-info-details span,.failed-txt, .success-txt,
    .subsc-footer-p, .subsc-footer-info li a,.subsc-data li p,.subsc-data li span{
        font-size:14px;
    }
    .payment-info-head span{
        font-size:16px;
    }
    .payment-info-details{
        padding:30px 16px;
    }
    .payment-success-result{
        padding:0;
    }
    .fail-tt, .success-tt{
        font-size:20px;
    }
    .subsc-footer-content{
        padding:30px 20px;
    }
    .subsc-footer-content{
        flex-direction:column;
        align-items:flex-start;
    }
    .subsc-footer-right{
        margin-top:20px;
    }
    .subsc-sosials li:first-child{
        margin-left:0;
    }
    .subsc-section{
        padding:30px 20px;
    }
    .subsc-data{
        margin-top:24px;
    }
    .back-btn{
        padding-left:10px;
        padding-right:10px;
    }
    .payment-btns{
        margin-top:32px;
    }
    .subsc-data li{
        padding-top:12px;
        padding-bottom:12px;
    }
    .payment-type-img span{
        width:40px;
    }
}
@media screen and (max-width:400px){
    .subsc-data li p{
        max-width:200px;
    }
}




/* Firefox */
input[type=number] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
}